<template>
<div>
  <div class="List" v-if="list.length">
    <template v-for="(item, index) in list">
      <div :key="index" class="List-item">
        <div class="List-item-left">
          <img v-if="item.image" :src="item.image">
          <div :class="['date', item.image ? 'right' : 'left']">
            <span>{{ formatDate(item.createDate).day }}/ </span>
            <span>
              {{ formatDate(item.createDate).month }}月<br />
              {{ formatDate(item.createDate).year }}
            </span>
          </div>
        </div>
        <div class="List-item-right">
          <div class="title">{{ item.title }}</div>
          <div class="digest">{{ item.description }}</div>
          <div class="bottom">
            <span class="detail" @click="handleViewDetail(item.id)">查看详情</span>
            <span class="source" v-if="item.source">来源：{{ item.source }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
  <div v-else class="empty-content">暂无数据</div>
  <el-pagination
    class="pagination-container"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="pagination.page"
    :page-sizes="[5, 10, 20, 30, 50]"
    :page-size="pagination.size"
    layout="total, sizes, prev, pager, next, jumper"
    :total="pagination.total">
  </el-pagination>
</div>
</template>

<script>
  export default {
    name: 'List',
    props: {
      categoryId: {
        type: String,
        default: ''
      },
    },
    data() {
      return {
        id: '',
        list: [],
        pagination: {
          page: 1,
          size: 5,
          total: 0
        }
      }
    },
    watch: {
      'categoryId': {
        immediate: true,
        handler(categoryId) {
          this.id = categoryId;
          this.getList();
        }
      }
    },
    mounted () {
      if(!this.id) {
        this.getList();
      }
    },
    methods: {
      async getList() {
        const { page, size } = this.pagination;
        const res = await this.$request.get('/article/search', { params: { page, size, categoryId: this.id } });
        this.list = res.data;
        this.pagination.total = res.total;
      },
      formatDate(date) {
        const newDate = new Date(date);
        let year = newDate.getFullYear();
        let month = `${newDate.getMonth() + 1}`.padStart(2, '0');
        let day = `${newDate.getDate()}`.padStart(2, '0');
        return {
          year,
          month,
          day
        }
      },
      handleViewDetail(id) {
        this.$router.push({ name: 'News.Details', params: { id } })
      },
      handleSizeChange(val) {
        this.pagination.size = val;
        this.getList()
      },
      handleCurrentChange(val) {
        this.pagination.page = val;
        this.getList()
      }
    },
  }
</script>

<style lang="scss" scoped>
.List{
  min-height: 500px;
  &-item{
    position: relative;
    padding-bottom: 30px;
    margin: 30px auto;
    display: flex;
    border-bottom: 1px solid rgba(193, 193, 201, 0.3);
    &-left{
      width: 270px;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid rgba(193, 193, 201, 0.3);
      img{
        width: 100%;
        height: 100%;
      }
      .date{
        display: flex;
        justify-content: center;
        align-items: center;
        span:first-child{
          font-size: 48px;
          font-family: DINAlternate-Bold, DINAlternate;
          // font-weight: bold;
          color: #3484FB;
        }
        span:last-child{
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          color: #B7B7B7;
        }
        &.left{

        }
        &.right{
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    &-right{
      padding: 10px 0 0 60px;
      flex: 1;
      .title{
        max-width: 600px;
        font-size: 18px;
        font-weight: 700;
        color: #171717;
        white-space:nowrap;
        text-overflow :ellipsis;
        overflow : hidden;
      }
      .digest{
        max-width: 600px;
        margin: 10px 0 30px;
        color: #5F5F6A;
        // white-space:nowrap;
        text-overflow :ellipsis;
        overflow : hidden;
        text-overflow: -o-ellipsis-lastline;
        display: -webkit-box;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;	
      }
      .bottom{
        display: flex;
        justify-content: space-between;
        .detail{
          color: #3484FB;
          cursor: pointer;
        }
        .source{
          color: #5F5F6A;
        }
      }
    }
  }
}
.empty-content{
  line-height: 500px;
  text-align: center;
  font-size: 16px;
  color: #5F5F6A;
}
.pagination-container{
  display: flex;
  justify-content: end;
}
</style>